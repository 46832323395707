import {
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
  FormControl,
  Typography,
} from "@mui/material";
import React from "react";
import palette from "../../../../../../config/colorPalette";
import { clone, isNil } from "ramda";
import { ErrorText } from "../styled";
import ThresholdsValues from "../../ThresholdsValues";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { handleChannels, maxHandler, minHandler } from "../utils";

const MeasurementInfo = ({
  e,
  equipment,
  measures,
  setMeasures,
  measuresAlerts,
  setMeasuresAlerts,
  measure,
  measureToggle,
  indexMeasure,
  measuresComputed,
  indexEquipment,
  thresholdsValues,
  setThresholdsValues,
  thresholdsRanges,
  setThresholdsRanges,
  thresholdType,
  channelToggle,
  setChannelToggle,
  zones,
  setZones,
  zoneToggle,
  setZoneToggle,
  polylineDisplay,
  errors,
  setErrors,
  intl,
}) => {
  return (
    <Collapse in={measureToggle[indexEquipment]}>
      <section
        style={{
          position: "relative",
          width: "100%",
          borderRadius: "4px",
          border: `1px solid ${palette.primary}`,
          margin: "1.5rem 0",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-17px",
            marginInlineStart: "10px",
          }}
        >
          <Chip
            label={`Measure ${indexMeasure + 1}`}
            variant="outlined"
            style={{
              backgroundColor: palette.primary,
              color: palette.likeWhite,
              borderColor: palette.primary,
            }}
            sx={{ "&.MuiChip-deleteIcon": { color: palette.likeWhite } }}
            onDelete={() => {
              if (measure !== "temperature" && measure !== "strain" && measure !== "vibrations") {
                const localMeasures = clone(measures);
                const localMeasuresAlerts = clone(measuresAlerts);
                const localThresholdsValues = clone(thresholdsValues);
                localMeasures[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                localMeasuresAlerts[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                localThresholdsValues[`equipment${indexEquipment + 1}`].splice(indexMeasure, 1);
                setMeasures(localMeasures);
                setMeasuresAlerts(localMeasuresAlerts);
                setThresholdsValues(localThresholdsValues);
              }
            }}
          />
        </div>
        <div style={{ padding: "1rem" }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12} sm={2} style={{ margin: "1rem" }}>
              <FormControl>
                <TextField
                  id={"measure" + indexEquipment}
                  variant="outlined"
                  style={{ margin: "0 0.2rem", width: "15rem" }}
                  label={intl.formatMessage({ id: "measure.name" })}
                  value={measure}
                  select={measure === "temperature" || measure === "strain" || measure === "vibrations" ? false : true}
                  disabled={
                    measure === "temperature" || measure === "strain" || measure === "vibrations" ? true : false
                  }
                  onChange={(e) => {
                    const localMeasures = clone(measures);
                    if (isNil(localMeasures[`equipment${indexEquipment + 1}`][indexMeasure])) {
                      localMeasures[`equipment${indexEquipment + 1}`][indexMeasure].push(e.target.value);
                    } else {
                      localMeasures[`equipment${indexEquipment + 1}`][indexMeasure] = e.target.value;
                    }
                    setMeasures(localMeasures);
                  }}
                  error={errors["measure" + indexEquipment] ? true : false}
                  required
                >
                  {(measure !== "temperature" || measure !== "strain" || measure !== "vibrations") &&
                    measuresComputed[`equipment${indexEquipment + 1}`].map((computing, index2) => (
                      <MenuItem
                        key={`equipment${indexEquipment + 1}_${index2}`}
                        value={computing}
                        disabled={measures[`equipment${indexEquipment + 1}`].includes(computing)}
                      >
                        {computing}
                      </MenuItem>
                    ))}
                </TextField>
                {!isNil(errors["measure" + indexEquipment]) && (
                  <ErrorText>{intl.formatMessage({ id: errors["measure" + indexEquipment] })}</ErrorText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid>
            {!isNil(measuresAlerts[`equipment${indexEquipment + 1}`][indexMeasure]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    id={"checkbox" + indexMeasure}
                    sx={{
                      color: palette.primary,
                      "&.Mui-checked": {
                        color: palette.primary,
                      },
                    }}
                    defaultChecked={measuresAlerts[`equipment${indexEquipment + 1}`][indexMeasure] === 1 ? true : false}
                    onChange={(e) => {
                      let newMA = clone(measuresAlerts);
                      if (e.target.checked) {
                        newMA[`equipment${indexEquipment + 1}`][indexMeasure] = 1;
                      } else {
                        newMA[`equipment${indexEquipment + 1}`][indexMeasure] = 0;
                      }
                      setMeasuresAlerts(newMA);
                    }}
                  />
                }
                label={intl.formatMessage({ id: "send.alert" })}
              />
            )}

            {measure === "temperature" || measure === "strain" || measure === "vibrations" ? (
              <>
                <Divider
                  orientation="horizontal"
                  flexItem
                  style={{ margin: "0 2rem", backgroundColor: palette.primary }}
                />
                {e.channels.map((channel, channelIndex) => (
                  <>
                    <h1
                      style={{ margin: "2%" }}
                      onClick={() => {
                        let newChannelToggle = clone(channelToggle);
                        if (newChannelToggle[indexEquipment][indexMeasure][channelIndex]) {
                          newChannelToggle[indexEquipment][indexMeasure][channelIndex] = false;
                        } else {
                          newChannelToggle[indexEquipment][indexMeasure][channelIndex] = true;
                        }
                        setChannelToggle(newChannelToggle);
                      }}
                    >
                      {intl.formatMessage({ id: "channel.mayus" })} {channelIndex + 1}
                      {!channelToggle[indexEquipment][indexMeasure][channelIndex] ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )}
                    </h1>
                    <Collapse in={channelToggle[indexEquipment][indexMeasure][channelIndex]}>
                      <TextField
                        id={"equipment" + indexEquipment + "_zones" + equipment}
                        style={{ margin: "0 0.2rem 0.8rem" }}
                        defaultValue={zones[indexEquipment][indexMeasure][channelIndex]}
                        inputProps={{ min: 1 }}
                        onChange={(e) => {
                          handleChannels({
                            e,
                            thresholdsValues,
                            channelIndex,
                            errors,
                            indexEquipment,
                            indexMeasure,
                            setErrors,
                            setThresholdsRanges,
                            setThresholdsValues,
                            setZones,
                            thresholdsRanges,
                            zones,
                            zoneToggle,
                          });
                        }}
                        type="number"
                        error={errors["equipment" + indexEquipment + "_zones" + equipment] ? true : false}
                        variant="outlined"
                        label={intl.formatMessage({ id: "measure.zones" })}
                        required
                      ></TextField>
                      {Array.from({
                        length: zones[indexEquipment][indexMeasure][channelIndex],
                      }).map((_, zoneIndex) => (
                        <>
                          <h4
                            style={{ margin: "2%", marginBottom: "5%" }}
                            onClick={() => {
                              let newZoneToggle = clone(zoneToggle);
                              if (newZoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex]) {
                                newZoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex] = false;
                              } else {
                                newZoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex] = true;
                              }
                              setZoneToggle(newZoneToggle);
                            }}
                          >
                            {intl.formatMessage({ id: "zone.mayus" })} {zoneIndex + 1}
                            {!zoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex] ? (
                              <ArrowDropDownIcon />
                            ) : (
                              <ArrowDropUpIcon />
                            )}
                          </h4>
                          <Collapse in={zoneToggle[indexEquipment][indexMeasure][channelIndex][zoneIndex]}>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <TextField
                                  inputProps={{ min: 0, step: "0.1" }}
                                  label={intl.formatMessage({ id: "range.min" })}
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  key={`equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`}
                                  error={
                                    errors[
                                      `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                    ]
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][
                                      zoneIndex
                                    ][0]
                                  }
                                  onChange={(e) => {
                                    minHandler({
                                      e,
                                      thresholdsRanges,
                                      setThresholdsRanges,
                                      indexEquipment,
                                      indexMeasure,
                                      channelIndex,
                                      zoneIndex,
                                      errors,
                                      setErrors,
                                    });
                                  }}
                                />
                                {!isNil(
                                  errors[
                                    `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                  ],
                                ) && (
                                  <ErrorText style={{ margin: "0.2rem" }}>
                                    {intl.formatMessage({
                                      id: errors[
                                        `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_0`
                                      ],
                                    })}
                                  </ErrorText>
                                )}
                              </Grid>
                              <Grid item xs={1} style={{ textAlign: "center" }}>
                                <Typography variant="h6">-</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  inputProps={{ min: 0, step: "0.1" }}
                                  key={`equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`}
                                  label={intl.formatMessage({ id: "range.max" })}
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={
                                    thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][
                                      zoneIndex
                                    ][1]
                                  }
                                  value={
                                    thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure][channelIndex][
                                      zoneIndex
                                    ][1]
                                  }
                                  error={
                                    errors[
                                      `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                    ]
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    maxHandler({
                                      e,
                                      thresholdsRanges,
                                      setThresholdsRanges,
                                      indexEquipment,
                                      indexMeasure,
                                      channelIndex,
                                      zoneIndex,
                                      polylineDisplay,
                                      equipment: equipment[indexEquipment],
                                      errors,
                                      setErrors,
                                    });
                                  }}
                                />
                                {!isNil(
                                  errors[
                                    `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                  ],
                                ) && (
                                  <ErrorText style={{ margin: "0.2rem" }}>
                                    {intl.formatMessage({
                                      id: errors[
                                        `equipment${indexEquipment + 1}]_${indexMeasure}_${channelIndex}_${zoneIndex}_1`
                                      ],
                                    })}
                                  </ErrorText>
                                )}
                              </Grid>
                            </Grid>
                            <ThresholdsValues
                              key={`equipment${indexEquipment}_${indexMeasure}_${channelIndex}_${zoneIndex}`}
                              thresholdTypes={thresholdType?.[0]}
                              errors={errors}
                              setErrors={setErrors}
                              measureIndex={indexMeasure}
                              equipment={`equipment${indexEquipment + 1}`}
                              thresholdsValues={thresholdsValues}
                              setThresholdsValues={setThresholdsValues}
                              thresholdsRanges={thresholdsRanges}
                              setThresholdsRanges={setThresholdsRanges}
                              equipmentIndex={indexEquipment}
                              channelIndex={channelIndex}
                              zoneIndex={zoneIndex}
                            />
                          </Collapse>
                        </>
                      ))}
                    </Collapse>
                  </>
                ))}
              </>
            ) : null}
          </Grid>
        </div>
      </section>
    </Collapse>
  );
};

export default MeasurementInfo;
