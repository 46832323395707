import styled from "styled-components";

export const MapTypeContent = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 0.7rem;
  left: 0.7rem;
`;

export const LegendContent = styled.div`
  position: absolute;
  z-index: 1000;
  top: 6rem;
  left: 0.7rem;
  background-color: rgb(255 255 255 / 88%);
  width: 11.5rem;
`;
export const EquipmentSelector = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0.7rem;
  right: 0.7rem;
  background-color: rgb(255 255 255 / 88%);
  width: 13.5rem;
  padding: 0.5rem;
`;

export const ViewFilterContent = styled.div`
  position: absolute;
  z-index: 2000;
  top: 0.7rem;
  right: 0.7rem;
  background-color: rgb(255 255 255 / 88%);
  width: 11rem;
  padding: 0.3rem;
`;
