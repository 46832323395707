import { Collapse, TextField, FormControlLabel, Checkbox, Select, MenuItem } from "@mui/material";
import React from "react";
import palette from "../../../../../../config/colorPalette";
import { clone } from "ramda";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { handleChannelDisability } from "../utils";

const ChannelInfo = ({
  e,
  equipment,
  setEquipment,
  channelPK,
  equipmentInterMeasurement,
  setEquipmentInterMeasurement,
  channelNameAsignToggle,
  channelDataToggle,
  setChannelDataToggle,
  setChannelNameAsignToggle,
  channelAsignToggle,
  setChannelAsignToggle,
  thresholdsRanges,
  indexEquipment,
  polylineDisplay,
  useStyles,
  setChannelPK,
  setThresholdsRanges,
  polylineColors,
  hoveredPolylineIndex,
  setHoveredPolylineIndex,
  errors,
  setErrors,
  BootstrapButton,
  intl,
}) => {
  return (
    <>
      <BootstrapButton
        variant="contained"
        id="name-to-channels"
        onClick={() => {
          let newChannel = clone(channelNameAsignToggle);
          if (newChannel[indexEquipment]) {
            newChannel[indexEquipment] = false;
          } else {
            newChannel[indexEquipment] = true;
          }
          setChannelNameAsignToggle(newChannel);
        }}
      >
        {intl.formatMessage({ id: "channel.names" })}
        {!channelNameAsignToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </BootstrapButton>
      <Collapse in={channelNameAsignToggle[indexEquipment]}>
        {e.channels.map((channel, indChan) => {
          return (
            <div style={{ margin: "2%", display: "flex", alignItems: "center" }}>
              <h1
                style={{ marginRight: "1%" }}
                id={`channel${indChan}_collapse`}
                onClick={() => {
                  let newChannel = clone(channelDataToggle);
                  if (newChannel[indexEquipment][indChan]) {
                    newChannel[indexEquipment][indChan] = false;
                  } else {
                    newChannel[indexEquipment][indChan] = true;
                    for (
                      let indexChannelData = 0;
                      indexChannelData < channelDataToggle[indexEquipment].length;
                      indexChannelData++
                    ) {
                      if (indexChannelData !== indChan) {
                        newChannel[indexEquipment][indexChannelData] = false;
                      }
                    }
                  }
                  setChannelDataToggle(newChannel);
                }}
              >
                {intl.formatMessage({ id: "channel" })} {indChan + 1}
                {!channelDataToggle[indexEquipment][indChan] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </h1>
              <Collapse in={channelDataToggle[indexEquipment][indChan]}>
                <TextField
                  id={`channel_name_${indChan}`}
                  style={{ margin: "0 0.2rem 0.8rem" }}
                  defaultValue={""}
                  onChange={(e) => {
                    const newEquipments = [...equipment];
                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                    updatedEquipment.channelsNames[indChan] = e.target.value;
                    newEquipments[indexEquipment] = updatedEquipment;
                    setEquipment(newEquipments);
                  }}
                  error={errors[`channel_name_${indChan}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "channel.name" })}
                  required
                ></TextField>
                <TextField
                  id={`channel_length_${indChan}`}
                  style={{ margin: "0 0.2rem 0.8rem" }}
                  inputProps={{ min: 0 }}
                  defaultValue={channelPK[indexEquipment][indChan]}
                  value={channelPK[indexEquipment][indChan]}
                  disabled={true}
                  onChange={(e) => {
                    const newEquipments = [...equipment];
                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                    updatedEquipment.channelsLength[indChan] = parseFloat(e.target.value);
                    newEquipments[indexEquipment] = updatedEquipment;
                    setEquipment(newEquipments);
                  }}
                  error={errors[`channel_length_${indChan}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "channel.length" })}
                  type="number"
                  required
                ></TextField>
                <TextField
                  id={`channel_spatial_resolution_${indChan}`}
                  style={{ margin: "0 0.2rem 0.8rem" }}
                  inputProps={{ min: 0 }}
                  defaultValue={0}
                  onChange={(e) => {
                    const newEquipments = [...equipment];
                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                    updatedEquipment.channelsSpatialResolution[indChan] = parseFloat(e.target.value);
                    newEquipments[indexEquipment] = updatedEquipment;
                    setEquipment(newEquipments);
                  }}
                  error={errors[`channel_spatial_resolution_${indChan}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "channel.spatial.resolution" })}
                  required
                  type="number"
                ></TextField>
                <TextField
                  id={`channel_sampling_interval_${indChan}`}
                  style={{ margin: "0 0.2rem 0.8rem" }}
                  inputProps={{ min: 0 }}
                  defaultValue={0}
                  onChange={(e) => {
                    const newEquipments = [...equipment];
                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                    updatedEquipment.channelsSamplingInterval[indChan] = parseFloat(e.target.value);

                    newEquipments[indexEquipment] = updatedEquipment;
                    setEquipment(newEquipments);
                  }}
                  error={errors[`channel_sampling_interval_${indChan}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "channel.sampling.interval" })}
                  required
                  type="number"
                ></TextField>
                <TextField
                  id={`channel_measurement_time_${indChan}`}
                  style={{ margin: "0 0.2rem 0.8rem" }}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    const newEquipments = [...equipment];
                    const updatedEquipment = { ...newEquipments[indexEquipment] };
                    updatedEquipment.channelsMeasurementTime[indChan] = parseFloat(e.target.value);
                    const suma = updatedEquipment.channelsMeasurementTime.reduce(
                      (acumulador, valorActual) => acumulador + valorActual,
                      0,
                    );
                    updatedEquipment.measureFrequency = suma;
                    newEquipments[indexEquipment] = updatedEquipment;
                    setEquipment(newEquipments);

                    let newInter = clone(equipmentInterMeasurement);
                    newInter[indexEquipment] = suma;
                    setEquipmentInterMeasurement(newInter);
                  }}
                  error={errors[`channel_measurement_time_${indChan}`] ? true : false}
                  variant="outlined"
                  label={intl.formatMessage({ id: "channel.measurement.time" })}
                  required
                  type="number"
                ></TextField>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"checkbox" + indChan}
                      sx={{
                        color: palette.primary,
                        "&.Mui-checked": {
                          color: palette.primary,
                        },
                      }}
                      defaultChecked={e.channelsStates[indChan]}
                      onChange={(e) => {
                        handleChannelDisability({
                          actualID: equipment + indexEquipment + "_frequency",
                          equipment,
                          equipmentInterMeasurement,
                          errors,
                          indChan,
                          indexEquipment,
                          newState: e.target.checked,
                          setEquipment,
                          setEquipmentInterMeasurement,
                          setErrors,
                        });
                      }}
                    />
                  }
                  label={intl.formatMessage({ id: "disable?" })}
                  style={{ marginLeft: "auto" }}
                />
                {e.channelsStates[indChan] ? (
                  <TextField
                    id={`channel_info_${indChan}`}
                    style={{ margin: "0 0.2rem 0.8rem" }}
                    inputProps={{ min: 0 }}
                    value={e.channelsInfo[indChan]}
                    onChange={(e) => {
                      const newEquipments = [...equipment];
                      const updatedEquipment = { ...newEquipments[indexEquipment] };
                      updatedEquipment.channelsInfo[indChan] = e.target.value;
                      newEquipments[indexEquipment] = updatedEquipment;
                      setEquipment(newEquipments);
                    }}
                    error={errors[`channel_info_${indChan}`] ? true : false}
                    variant="outlined"
                    label={intl.formatMessage({ id: "channel.info" })}
                  />
                ) : null}
              </Collapse>
            </div>
          );
        })}
      </Collapse>
      <BootstrapButton
        variant="contained"
        id="polyline-to-channels"
        onClick={() => {
          let newChannel = clone(channelAsignToggle);
          if (newChannel[indexEquipment]) {
            newChannel[indexEquipment] = false;
          } else {
            newChannel[indexEquipment] = true;
          }
          setChannelAsignToggle(newChannel);
        }}
      >
        {intl.formatMessage({ id: "channels.to.polylines" })}
        {!channelAsignToggle[indexEquipment] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </BootstrapButton>
      <Collapse in={channelAsignToggle[indexEquipment]}>
        {e.channels.map((channel, indChan) => {
          return (
            <div style={{ margin: "2%", display: "inline-block" }}>
              {indChan + 1}.
              <Select
                id={e.equipmentID + "channel" + indChan}
                onChange={(e) => {
                  const newEquipments = [...equipment];
                  const updatedEquipment = { ...newEquipments[indexEquipment] };
                  updatedEquipment.channels[indChan] = parseInt(e.target.value);
                  newEquipments[indexEquipment] = updatedEquipment;
                  let cloneRange = clone(thresholdsRanges);
                  for (
                    let indexEquipment = 0;
                    indexEquipment < Object.keys(thresholdsRanges).length;
                    indexEquipment++
                  ) {
                    for (
                      let indexMeasure = 0;
                      indexMeasure < thresholdsRanges[`equipment${indexEquipment + 1}`].length;
                      indexMeasure++
                    ) {
                      for (
                        let indexChannel = 0;
                        indexChannel < thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure].length;
                        indexChannel++
                      ) {
                        if (indexChannel === indChan) {
                          for (
                            let indexZone = 0;
                            indexZone <
                            thresholdsRanges[`equipment${indexEquipment + 1}`][indexMeasure][indexChannel].length;
                            indexZone++
                          ) {
                            cloneRange[`equipment${indexEquipment + 1}`][indexMeasure][indexChannel][indexZone][1] =
                              Math.round(
                                polylineDisplay[parseInt(e.target.value)].points[
                                  polylineDisplay[parseInt(e.target.value)].points.length - 1
                                ].pk * 100,
                              ) / 100;
                          }
                        }
                      }
                    }
                  }
                  let newChannelPk = clone(channelPK);
                  newChannelPk[indexEquipment][indChan] =
                    Math.round(
                      polylineDisplay[parseInt(e.target.value)].points[
                        polylineDisplay[parseInt(e.target.value)].points.length - 1
                      ].pk * 100,
                    ) / 100;
                  updatedEquipment.channelsLength[indChan] = parseFloat(
                    Math.round(
                      polylineDisplay[parseInt(e.target.value)].points[
                        polylineDisplay[parseInt(e.target.value)].points.length - 1
                      ].pk * 100,
                    ) / 100,
                  );
                  setChannelPK(newChannelPk);
                  setThresholdsRanges(cloneRange);
                  setEquipment(newEquipments);
                }}
                defaultValue={channel}
              >
                {polylineDisplay.map((p, indPoly) => {
                  return (
                    <MenuItem
                      key={e.equipmentID + "channel" + indChan + "_" + indPoly}
                      value={indPoly}
                      onMouseOver={() => {
                        if (polylineDisplay.length > 10) {
                          setHoveredPolylineIndex(null);
                          setTimeout(() => {
                            setHoveredPolylineIndex(indPoly);
                          }, 0);
                        }
                      }}
                      onMouseOut={() => {
                        setHoveredPolylineIndex(null);
                      }}
                    >
                      {polylineDisplay.length > 10 ? (
                        <>{indPoly + 1}</>
                      ) : (
                        <div
                          className={useStyles.colorSample}
                          style={{
                            backgroundColor: polylineColors[indPoly],
                            width: "20px",
                            height: "20px",
                            display: "inline-block",
                            marginRight: "8px",
                            border: "1px solid #ccc",
                          }}
                        ></div>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          );
        })}
      </Collapse>
    </>
  );
};

export default ChannelInfo;
